<template>
  <div class="main-data container col">
    <div class="row">
      <div class="card">
        <div class="card-heading"><span>Linnaeus Dictation App</span></div>
        <div id="kc-container" class="card-content">

          <!-- loader -->
            <div class = "preloader-wrapper big active loader hide">
              <div class = "spinner-layer spinner-blue-only">
                <div class = "circle-clipper left">
                  <div class = "circle"></div>
                </div>
                  
                <div class = "gap-patch">
                  <div class = "circle"></div>
                </div>
                  
                <div class = "circle-clipper right">
                  <div class = "circle"></div>
                </div>
              </div>
            </div>
            <!-- loader -->

          <div class="listOfQuestions">
          </div>
          <h6><b>Lets capture</b></h6>
          <p class="questionText">Click record to start your dictation
          </p>
          <div id="formdata" class="btn-section">
            
            <button 
              id="record_q1" 
              type="button" 
              class="btn kc record" 
              :class="{ hide: isRecordDisabled }" 
              :disabled="isRecordDisabled" 
              @click="startRecording">Record</button>
            <button type="button" class="btn kc pause" :class="{ hide: isPauseDisabled }" id="pause_q1" :disabled="isPauseDisabled" @click="pauseRecording">Pause</button>
            <button type="button" class="btn kc resume" :class="{ hide: isResumeDisabled }" id="resume_q1" :disabled="isResumeDisabled" @click="resumeRecording">Resume</button>
            <button type="button" class="btn kc stop" :class="{ hide: isStopDisabled }" id="stop_q1" :disabled="isStopDisabled" @click="stopRecording">Stop</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="js">
import { ref, defineComponent } from 'vue'
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import QBMediaRecorder from 'media-recorder-js'
export default defineComponent({
  name: 'HelloWorld',
  // props: {
  //   msg: {
  //     type: String,
  //     required: true
  //   }
  // },
  data() {
    return {
      isRecordDisabled: true,
      isPauseDisabled: true,
      isResumeDisabled: true,
      isStopDisabled: true,
      stream: null,
      blob: null,
      recorder: this._initRecorder(),
      isSaved: false
    }
  },
  mounted() {
    // console.log(this.recorder)
    if(QBMediaRecorder.isAvailable() || QBMediaRecorder.isAudioContext()){
      this.isRecordDisabled = false;
    }
  },
  methods: {
    _initRecorder () {
      return new QBMediaRecorder({
      onstart: function onStart() {
        console.log('started');
      },
      onstop: this.saveRecording.bind(this),
      mimeType: 'audio/mp3',
      workerPath: '../../qbAudioRecorderWorker.js'
    });
    },
    startRecording() {
      navigator.mediaDevices
        .getUserMedia({
          video: false,
          audio: {
            channelCount: 1,
            echoCancellation: false
          }
        })
        .then(this.record.bind(this))
        .catch(function(err) {
          // self.resetRecord();
          // self.blockRecorder("(microphone wasn't found)");
          console.error(err);
        });
      
    },
    record(streamer) {
      this.stream = streamer;
      this.isRecordDisabled = true;
      this.isPauseDisabled = false;
      this.isStopDisabled = false;
      this.blob = null;
      this.isSaved = false;
      this.recorder.start(this.stream);
    },
    pauseRecording() {
      this.isPauseDisabled = true;
      this.isResumeDisabled = false;
      this.recorder.pause();
    },
    resumeRecording() {
      this.isResumeDisabled = true;
      this.isPauseDisabled = false;
      this.recorder.resume();
    },
    stopRecording() {
      this.isRecordDisabled = false;
      this.isPauseDisabled = true;
      this.isResumeDisabled = true;
      this.isStopDisabled = true;
      console.log('stopping...');
      this.recorder.stop();
      this.stream.getTracks().forEach(function(track) { track.stop() });
      console.log('stopped.');
    },
    saveRecording(blob) {
      if (!this.isSaved) {
        console.log('saving...');
        Storage.put(uuidv4()+'.mp3', blob, {
          level: 'private',
          contentType: 'audio/mp3'
        }).then(this.logComplate.bind(this))
        .catch(function(err) {
            // self.resetRecord();
            // self.blockRecorder("(microphone wasn't found)");
            console.error(err);
          });
      }  
    },
    logComplate(result) {
      this.isSaved = true;
      console.log(result);
    }
  },
  setup: () => {
    const count = ref(0)
    return { count }
  }
})
</script>

<style scoped>
body{
  font-family: "Source Sans Pro";
}

.container{
  width:90% !important;
}
.card-heading {
  height: 50px;
  background-color: #249BD1;
  color: #fff;
  font-size: 20px;
  padding: 10px 15px;
}

.main-data .btn {
  height: 50px;
  width: 200px;
  background-color: #249BD1;
}

/* .btn-capture {

} */

.btn-section {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}

.footer-container {
  height: 35px;
  background: black;
  color: white;
  padding: 5px 0;
}

.video-container-box {
  width: 320px;
  height: 200px;
}

.form-ico {
  position: absolute;
  height: 20px;
  margin-top: 25px;
}

.form-box {
  margin-left: 25px;
}

.form-container-box {
  display: flex;
  flex-direction: column;
}

.main-data nav{
  background: #222F3D;
  height: 45px;
  line-height: 45px;
}

.small-font{
  font-size: 12px
}

nav ul li:hover, nav ul li .active-nav{
  border-bottom: 2px solid #005B9D;
}

.btn:hover{
  background: #212F3C !important;
}

.footer-container{
  background: #222F3D;
  line-height: 2;
}

.main-data .btn-section .kc{
    border: 3px solid #035F8C;
    border-radius: 30px;
    background: #007BB5;
}

.fa-lg{
  vertical-align: -55%;
}

button[disabled]{
  cursor: no-drop !important;
}

div.listOfQuestions{
  text-align: center;
}
div.listOfQuestions button{
  width: 40px;
  height: 40px;
  border: 3px solid #035F8C;
  background: #007BB5;
  border-radius: 50%;  
  color: #fff;
  margin-left: 30px;
}

div.listOfQuestions button[disabled]{
  background: #DFDFDF;
  color: #666666;
}

.loader {
  position: absolute !important;
  top :0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.overlay {
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
}
</style>

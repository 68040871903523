import { createApp } from 'vue'
import App from './App.vue'
import Amplify from 'aws-amplify';
import 'materialize-css/dist/css/materialize.min.css'
import 'material-design-icons/iconfont/material-icons.css'
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure({
    Auth: {
        identityPoolId: 'eu-west-2:5f3a2c8b-2293-4dc0-a1fc-42addb603267', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'eu-west-2', // REQUIRED - Amazon Cognito Region
        userPoolId: 'eu-west-2_PlxxSuoSh', //OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: '2ffna8s481jvsefaqbimlknclv', //OPTIONAL - Amazon Cognito Web Client ID
        mandatorySignIn: false,
    },
    Storage: {
        AWSS3: {
            bucket: 'linnaeus-uploads', //REQUIRED -  Amazon S3 bucket name
            region: 'eu-west-2', //OPTIONAL -  Amazon service region
        }
    }
  });

applyPolyfills().then(() => {
  defineCustomElements(window);
});

createApp(App).mount('#app')
